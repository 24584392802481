<!--
 * @Author: lbh
 * @Date: 2024-08-30 16:12:46
 * @LastEditors: lbh
 * @LastEditTime: 2024-09-11 10:52:22
 * @Description: 橫向滾動
-->
<template>
  <div
    class="scroll-row-bar-box px-scroll-row-bar-box"
    style="--padding: 50px"
    @click="clickItem('items')"
  >
    <div ref="swiper-container" class="swiper-container">
      <div class="swiper-wrapper" ref="content">
        <!-- Swiper的内容 -->
        <div
          v-for="(item, index) in configs.items"
          :key="index"
          class="swiper-slide"
        >
          <div class="above">
            <p class="words" v-html="$util.rex.getHtml(item[`${nowL.code}desc`] || item.desc)"></p>
            <div class="dates" v-text="$util.rex.getHtml(item[`${nowL.code}yyyy`] || item.yyyy)"></div>
          </div>
          <div class="lines"><em class=""></em><strong></strong></div>
        </div>
      </div>
    </div>
    <div
      v-if="!isMobile"
      class="scroll-row-handle"
      :style="{ '--w': handleW + 'px' }"
    >
      <div
        class="handle"
        ref="handle"
        @mousedown="handleMouseDown"
        @touchstart="handleMouseDown"
      ></div>
    </div>
  </div>
</template>

<script>
import Swiper from 'swiper';
// Import Swiper styles
import 'swiper/css/swiper.css';
import langMixin from '../../mixins/lang';
export default {
  name: 'scroll-row-bar',
  mixins: [langMixin],
  props: {
    activeName: {
      type: String,
      default: '0',
    },
    configs: {
      type: Object,
      default: () => ({}),
    },
  },
  watch: {
    activeName() {
      let is = this.$util.window.get().type == 1;
      if (this.activeName == 1 && !is) {
        this.initSwiper();
      }
    },
  },
  computed: {
    isMobile() {
      return this.$util.window.get().type == 1;
    },
  },
  data() {
    return {
      isDown: false,
      startX: 0,
      handleW: 30,
      handleWP: 0,
      contentW: 0,
      mySwiper: null,
    };
  },
  mounted() {
    // * pc
    window.addEventListener('mousemove', this.handleMouseMove, false);
    window.addEventListener('mouseup', this.handleMouseUp, false);
    // * 移動
    window.addEventListener('touchmove', this.handleMouseMove, false);
    window.addEventListener('touchend', this.handleMouseUp, false);
  },
  unmounted() {
    // * pc
    window.removeEventListener('mousemove', this.handleMouseMove, false);
    window.removeEventListener('mouseup', this.handleMouseUp, false);
    // * 移動
    window.removeEventListener('touchmove', this.handleMouseMove, false);
    window.removeEventListener('touchend', this.handleMouseUp, false);
  },
  methods: {
    // * 初始化 輪播
    initSwiper() {
      let mySwiper = new Swiper(this.$refs['swiper-container'], {
        slidesPerView: 3,
        spaceBetween: 0,
        on: {
          init: () => {
            this.init();
          },
          setTranslate: (translate) => {
            //自定义事件
            let handle = this.$refs.handle;
            // ? 父級距離左邊
            // * 鼠標點 - 父容器邊距 - 初始距離
            let bfb = (translate / this.contentW) * -1;
            let cha = this.handleWP * bfb;
            handle.style.cssText = `transform: translateX(${cha}px);`;
          },
        },
      });
      this.mySwiper = mySwiper;
    },

    // * 點擊編輯
    clickItem(e) {
      let isEdit = this.$store.getters.getIsEdit;
      if (isEdit) {
        this.$emit('clickItem', e, 1);
      }
    },

    // * 初始化參數
    init() {
      let { content, handle } = this.$refs;
      // ? 內容寬度
      let contentWidth = content.scrollWidth;

      // ? 內容父容器寬度
      let contentPWidth = content.parentElement.clientWidth;
      let padding = getComputedStyle(content.parentElement).paddingLeft;
      padding = Number(padding.replace('px', ''));
      contentWidth += padding;
      // ? 滑動塊 寬度
      let handleWidth = handle.scrollWidth;
      // ? 滑動塊 父容器寬度
      let handlePWidth = handle.parentElement.clientWidth;
      // ? 內容寬度 / 內容父容器寬度 = 滑動塊 寬度 / 滑動塊 父容器寬度
      let handleW = handlePWidth / (contentWidth / contentPWidth);
      // ? 內容寬度 - 內容父容器寬度
      this.handleWP = handlePWidth - handleW;
      this.handleW = handleW;
      // ? 內容寬度 - 內容父容器寬度
      this.contentW = contentWidth - contentPWidth + padding;
    },

    // * 鼠標點擊
    handleMouseDown(event) {
      let handle = this.$refs.handle;
      let { x: handleLeft } = handle.getBoundingClientRect();
      // * 初始距離
      let clientX =
        event.type == 'mousemove' || event.type == 'mousedown'
          ? event.clientX
          : event.touches[0].clientX;
      this.startX = clientX - handleLeft;
      // this.init();
      this.isDown = true;
    },

    // * 鼠標移動
    handleMouseMove(event) {
      if (this.isDown) {
        let clientX =
          event.type == 'mousemove' || event.type == 'mousedown'
            ? event.clientX
            : event.touches[0].clientX;
        let handle = this.$refs.handle;
        // ? 父級距離左邊
        let { x: handlePLeft } = handle.parentElement.getBoundingClientRect();
        // * 鼠標點 - 父容器邊距 - 初始距離
        let cha = clientX - handlePLeft - this.startX;
        if (cha > this.handleWP) cha = this.handleWP;
        if (cha < 0) cha = 0;
        let bfb = cha / this.handleWP;
        // document.querySelector('.content').style.setProperty('--left', bfb);
        this.mySwiper.setTranslate(this.contentW * bfb * -1);
        handle.style.cssText = `transform: translateX(${cha}px);`;
      }
    },
    handleMouseUp() {
      this.isDown = false;
    },
  },
};
</script>

<style lang="less" scoped>
@media screen and(min-width:701px) {
  .scroll-row-bar-box {
    padding: var(--font80) 0;
    .swiper-container {
      padding: 0 var(--font150);
      .swiper-wrapper {
        align-items: flex-end;
        .swiper-slide {
          height: 100%;
          .above {
            padding-right: 30px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            flex: 1;
            .words {
              font-weight: 400;
              color: #1c1c1c;
              font-size: 18px;
              user-select: none;
            }
            .dates {
              font-weight: bold;
              color: #1c1c1c;
              font-size: 40px;
              user-select: none;
              margin: 20px 0;
            }
          }
          .lines {
            display: flex;
            align-items: center;
            margin-top: auto;
            em {
              box-sizing: border-box;
              display: inline-block;
              width: 20px;
              height: 20px;
              background: var(--themeColor);
              border: 0.5px solid #fff;
              border-radius: 50%;
            }
            strong {
              flex: 1;
              display: inline-block;
              height: 10px;
              background: var(--themeColor5);
              margin: 0 8px;
            }
          }
        }
      }
    }
    .scroll-row-handle {
      height: 20px;
      width: 300px;
      background-color: #efefef;
      margin: var(--font50) var(--font150);
      border-radius: 10px;
      overflow: hidden;
      .handle {
        height: 100%;
        width: var(--w, 30px);
        cursor: pointer;
        background-color: var(--themeColor);
        border-radius: 10px;
      }
    }
  }
}
@media screen and(max-width:700px) {
  .px-scroll-row-bar-box {
    padding: var(--font20);
    .swiper-container {
      .swiper-wrapper {
        flex-direction: column;
        .swiper-slide {
          height: 100%;
          display: flex;
          flex-direction: row-reverse;
          .above {
            padding-left: 10px;
            display: flex;
            flex-direction: column-reverse;
            justify-content: space-between;
            flex: 1;
            .words {
              font-weight: 400;
              color: #1c1c1c;
              font-size: 14px;
              user-select: none;
              margin-bottom: 40px;
            }
            .dates {
              font-weight: bold;
              color: #1c1c1c;
              user-select: none;
              font-size: 22px;
            }
          }
          .lines {
            display: flex;
            flex-direction: column;
            align-items: center;
            em {
              box-sizing: border-box;
              display: inline-block;
              width: 15px;
              height: 15px;
              background: var(--themeColor);
              border: 0.5px solid #fff;
              border-radius: 50%;
              margin-top: 8px;
            }
            strong {
              flex: 1;
              display: inline-block;
              width: 1px;
              background: var(--themeColor5);
              // margin: 0 8px;
            }
          }
        }
      }
    }
    .scroll-row-handle {
      height: 20px;
      width: 300px;
      background-color: #efefef;
      margin: var(--font50) var(--font150);
      border-radius: 10px;
      overflow: hidden;
      .handle {
        height: 100%;
        width: var(--w, 30px);
        cursor: pointer;
        background-color: var(--themeColor);
        border-radius: 10px;
      }
    }
  }
}
</style>
