<!--
 * @Author: lbh
 * @Date: 2023-11-28 14:38:06
 * @LastEditors: lbh
 * @LastEditTime: 2024-08-30 16:16:55
 * @Description: file content
-->
<template>
  <div class="test-box">
    <selfScrollRowBar />
  </div>
</template>

<script>
import selfScrollRowBar from '@/components/do/scrollRowBar';

export default {
  name: 'test-index',
  components: {
    selfScrollRowBar,
  },
};
</script>

<style lang="less" scoped>
.test-box {
}
</style>
